var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"59a8402ed04a0d1d66d4fa4bd132ecd6a0232345"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

Sentry.init({
	dsn: 'https://e93394bd69d240ff8109475f020ee9cb@o216501.ingest.sentry.io/4505054459658240',
	tracesSampleRate: 1,
	debug: false,
	environment: process.env.VERCEL_ENV,
})
